<template>
	<div class=" full-height   flex-column justify-space-between ">
		<div class="full-height overflow-y-auto mt-30">
			<div
				v-if="list_nft.length > 0"
				class="main_card mt-20 pa-20"
			>
				<ul
					class="justify-start gap-20 flex-wrap swap  "
				>
					<li
						v-for="(item, index) in list_nft"
						:key="'item_' + index"
						@click="item_swap = item"
						class="position-relative"
					>
						<div
							class="square-80 "
							:class="{ on: item_swap.cartl_nft_number == item.cartl_nft_number}"
						>
							<img :src="item.cartl_nft_img_url" class="object-cover "/>
						</div>

						<img
							v-if="item_swap.cartl_nft_number == item.cartl_nft_number"
							:src="require('@/assets/image/icon-check.svg')"
							class="position-absolute"
							style="left: 5px; top: 5px"
						/>

					</li>
				</ul>
			</div>
			<Empty
				v-else
				class="color-white"
			></Empty>
		</div>

		<div
			v-if="list_nft.length > 0"
			class="mt-auto btn_area pa-20"
		>
			<button
				class="btn_fill_blue btn_l"
				:disbled="is_disable"
				@click="toItem"
			>{{ $language.common.next }}</button>
		</div>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
export default{
	name: 'NftSwap'
	, props: ['Axios', 'sample', 'user']
	, components: {Empty}
	, data: function(){
		return {
			program: {
				name: 'nft swap'
				, type: 'popup'
				, title: 'NFT SWAP'
				, from: 'mafia018'
			}
			, items: {
				holding_cartl_nft_list: []
				, holding_external_nft_list: []
			}
			, item_swap: {
				cartl_nft_number: ''
			}
		}
	}
	,computed: {
		is_disable: function(){
			let t = true
			if(this.item_swap.cartl_nft_number){
				t = false
			}
			return t
		}
		, list_nft: function(){
			let t = []
			this.items.holding_cartl_nft_list.filter( (item) => {
				t.push((item))
			})
			this.items.holding_external_nft_list.filter( (item) => {
				t.push((item))
			})
			return t
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_swap_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items = result.data
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toItem: function(){
			if(this.item_swap.cartl_nft_number){
				this.$bus.$emit('to', { name: 'NftSwapItem', params: {idx: this.item_swap.cartl_nft_number }})
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>
<style>
.card_info { float: none; text-align: right;}

.swap .on img {
	border: 2px solid var(--blue02);
	box-sizing: content-box;
}
</style>